<template>
  <div class="stayBottom">
    <dashboard-navbar></dashboard-navbar>

    <div class="main-content">
      <router-view></router-view>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Custom Components
import dashboardNavbar from '../../components/customer/Dashboard-Navbar.vue';
import mainFooter from '../../components/customer/Main-Footer.vue';

export default {
  components: {
    'dashboard-navbar': dashboardNavbar,
    'main-footer': mainFooter,
  },
};
</script>

<style scoped>
/* Init Style */
.main-content {
  padding: 110px 60px 150px 350px;
}

@media print {
  .main-content {
    padding: 0px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}
</style>
